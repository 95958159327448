/*
 * Module: PixelTracker
 *
 * @author Neil Palmer / UI Farm
 */
import { Logger } from '../util/logger';
import { Fetch } from '../api/fetch';
import { RequestUtil } from '../util/requestUtil';
import { EventManager } from '../util/eventManager';

const PixelTracker = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    let config = {
        confirmationContainerID : 'mc--section-confirmation'
    },

    init = function() {

        const thankYouPageEl = document.getElementById( config.confirmationContainerID );
        if( thankYouPageEl ) {
            const cid = localStorage.getItem( 'cid' );
            if( cid ) {
                let image = new Image( 1,1 );
                image.src = 'https://signadios-lodsource.icu/postback?cid=' + cid;
                localStorage.removeItem( 'cid' );
            }
        }

    };

    return {
        init : init
    };

})();

export { PixelTracker };