/*
 * Module: BMApp
 *
 * @author Neil Palmer / UI Farm
 */
import _ from 'underscore';
import { RequestUtil } from './util/requestUtil';
import { LeadForm } from './view/leadForm';
import { GTM } from './tracking/gtm';
import { PixelTracker } from './tracking/pixelTracker';
import { ScrollTo } from './ui/scrollTo';
import { FormSelect } from './ui/forms/formSelect';
import { LazyImages } from './ui/lazyImages';
import { LazyListings } from './ui/lazyListings';
import { Navbar } from './ui/nav/navbar';
import { Subscribe } from './ui/forms/subscribe';
import { SubscribeModal } from './ui/header/subscribeModal';
import { TabbedContent } from './ui/tabbedContent';
import { CTSearchWidget } from './ui/forms/ctSearchWidget';
import { CallButton } from './ui/header/callButton';
import { TwilioCall } from './ui/call/twilioCall';
import { LatestArticles } from './modules/latestArticles';
import { TrialListings } from './ui/listings/trial-listings';
import { Transitions } from './animation/transitions';
import { DOMElements as DOMHelper } from './dom/domElements';
import Rellax from 'rellax';

const Bootstrap = ( function() {

    let config = {
        defaults : {
            main : true
        }
    };

    let initApp = function( options ) {

        // Get options
        options = _.extend( config.defaults, options || {} );

        // Run default modules
        runDefaultModules();

        // Run application specific modules
        options.main ? runMainSiteModules() : runLeadGenModules();

        let rellaxInit = new Rellax( '.rellax' );

    },

    runDefaultModules = function() {
        GTM.init();
        PixelTracker.init();
        ScrollTo.doScroll();
        FormSelect.init();
    },

    runMainSiteModules = function() {
        if( isCallPg() ) {
            TwilioCall.init();
        } else {
            Transitions.init();
            LazyImages.doLazyBackgroundImages();
            LazyListings.init();
            Navbar.init();          
            Subscribe.init();
            SubscribeModal.init();
            TabbedContent.init();
            CTSearchWidget.init();
            LatestArticles.init();
            TrialListings.init();
            RequestUtil.saveCurrentPage();
        }
    },

    runLeadGenModules = function() {
        LeadForm.init();
        CallButton.init();
    },

    isCallPg = function() {
        return DOMHelper.isPresent( 'body.call' );
    };

    return {
        init : initApp
    };

})();

export { Bootstrap };
